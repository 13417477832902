@import '~antd/dist/antd.less';

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

.ant-form-item-explain {
  padding: 4px 0;
  margin-bottom: 8px;
  font-size: 14px;
}

.ant-checkbox-inner {
  border-radius: 2px;
}

.ant-form-item-control-input-content {
  font-weight: 600;
}

.flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border: 1px solid #cacaca;
}

/* react-phone-input-2 */
.react-tel-input {
  font-family: @font-family;
  position: relative;
  width: 100%;
}

.react-tel-input .form-control {
  font-size: @font-size-base;
  background: #FFFFFF;
  border: @border-width-base solid @input-border-color;
  border-radius: @border-radius-base;
  height: @input-height-base;
  width: 100%;
  outline: none;
}

.react-tel-input .form-control:focus {
  border-color: @primary-color;
}

.react-tel-input .country-list {
  max-height: 200px;
  margin-left: 1px;
  margin-bottom: 10px;
  border: @border-width-base solid @input-border-color;
}

.react-tel-input .selected-flag.open,
.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:hover,
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
  border: none;
}

.react-tel-input .flag-dropdown {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 3px;
  border: none;
}

@primary-color: #080F38;@font-family: Poppins, 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
     'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@text-color-secondary: #AFAFAF;@text-color: #858585;@heading-color: #535353;@font-size-base: 16px;@font-size-lg: 16px;@input-height-base: 60px;@input-height-lg: 60px;@input-height-sm: 40px;@input-placeholder-color: #CFCFCF;@border-radius-base: 8px;@border-width-base: 2px;@btn-primary-shadow: 0 7px 25px 0 #080f3844;@btn-border-radius-base: 10px;@input-padding-horizontal: 21px;@input-icon-color: #CFCFCF;@drawer-bg: #F9F9FC;